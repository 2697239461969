const React = require("react")
// normalize CSS across browsers
require("./src/normalize.css")
// Import Swiper styles
require('swiper/swiper.scss');
require("swiper/components/pagination/pagination.min.css");

// templated CSS styles
require("./src/style.css")

// GH styles
require("./src/typography.css")
require("./src/variables.css")

// Custom Bootstrap
require("./src/bootstrap.scss")

// Blog post stylesheet
require("./src/blog.styles.scss")

// Learning center post stylesheet
require("./src/learning-center.styles.scss")

// Slick slider styles
require("slick-carousel/slick/slick.css")
require("slick-carousel/slick/slick-theme.css")

// Custom styles
require("./src/custom.styles.scss")

const { AnalyticsContextProvider } = require("./src/context");

 exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
  }
}

//Wrap the page element with the live chat loader so that it only gets loaded once
exports.wrapPageElement = ({ element }) => {
  return(
    <div className="custom-page-wrapper">
        {element}
    </div>
  )
}

exports.wrapRootElement = ({ element }) => {
  return(
    <AnalyticsContextProvider>
      {element}
    </AnalyticsContextProvider>
  )
}