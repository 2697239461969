import React, { createContext, useState } from 'react'
// import PropTypes from 'prop-types'

const defaultState = {
    notificationBannerIsOpen: true,
    closeNotificationBanner: () => {},
}
//TODO: Set up this context to save the state of the notification banner so that it persists past page reloads/changes

export const Context = createContext(defaultState)

export const Provider = props => {
  // Initial values are obtained from the props
  const { children } = props

  // Use State to keep the values
  const [ notificationBannerIsOpen, setNotificationBannerIsOpen ] = useState(true)
  

  const closeNotificationBanner = () => { setNotificationBannerIsOpen(false) }

  const notificationContext = {
    notificationBannerIsOpen,
    closeNotificationBanner
  }

  // pass the value in provider and return
  return <Context.Provider value={notificationContext}>{children}</Context.Provider>
}

export const { Consumer } = Context