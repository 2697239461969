// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-grubhub-corporate-accounts-js": () => import("./../../../src/pages/about-grubhub-corporate-accounts.js" /* webpackChunkName: "component---src-pages-about-grubhub-corporate-accounts-js" */),
  "component---src-pages-about-grubhub-corporate-js": () => import("./../../../src/pages/about-grubhub-corporate.js" /* webpackChunkName: "component---src-pages-about-grubhub-corporate-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-back-to-work-js": () => import("./../../../src/pages/back-to-work.js" /* webpackChunkName: "component---src-pages-back-to-work-js" */),
  "component---src-pages-catering-for-corporate-js": () => import("./../../../src/pages/catering-for-corporate.js" /* webpackChunkName: "component---src-pages-catering-for-corporate-js" */),
  "component---src-pages-catering-js": () => import("./../../../src/pages/catering.js" /* webpackChunkName: "component---src-pages-catering-js" */),
  "component---src-pages-contact-us-enterprise-account-js": () => import("./../../../src/pages/contact-us-enterprise-account.js" /* webpackChunkName: "component---src-pages-contact-us-enterprise-account-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-contact-us-standard-account-js": () => import("./../../../src/pages/contact-us-standard-account.js" /* webpackChunkName: "component---src-pages-contact-us-standard-account-js" */),
  "component---src-pages-corporate-accounts-pricing-js": () => import("./../../../src/pages/corporate-accounts-pricing.js" /* webpackChunkName: "component---src-pages-corporate-accounts-pricing-js" */),
  "component---src-pages-corporate-catering-js": () => import("./../../../src/pages/corporate-catering.js" /* webpackChunkName: "component---src-pages-corporate-catering-js" */),
  "component---src-pages-corporate-tax-exempt-food-delivery-js": () => import("./../../../src/pages/corporate-tax-exempt-food-delivery.js" /* webpackChunkName: "component---src-pages-corporate-tax-exempt-food-delivery-js" */),
  "component---src-pages-employee-gift-cards-js": () => import("./../../../src/pages/employee-gift-cards.js" /* webpackChunkName: "component---src-pages-employee-gift-cards-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-feed-digital-events-js": () => import("./../../../src/pages/feed-digital-events.js" /* webpackChunkName: "component---src-pages-feed-digital-events-js" */),
  "component---src-pages-feed-remote-employees-js": () => import("./../../../src/pages/feed-remote-employees.js" /* webpackChunkName: "component---src-pages-feed-remote-employees-js" */),
  "component---src-pages-feed-remote-teams-js": () => import("./../../../src/pages/feed-remote-teams.js" /* webpackChunkName: "component---src-pages-feed-remote-teams-js" */),
  "component---src-pages-feed-your-digital-events-js": () => import("./../../../src/pages/feed-your-digital-events.js" /* webpackChunkName: "component---src-pages-feed-your-digital-events-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-group-order-js": () => import("./../../../src/pages/group--order.js" /* webpackChunkName: "component---src-pages-group-order-js" */),
  "component---src-pages-group-ordering-js": () => import("./../../../src/pages/group-ordering.js" /* webpackChunkName: "component---src-pages-group-ordering-js" */),
  "component---src-pages-group-orders-js": () => import("./../../../src/pages/group-orders.js" /* webpackChunkName: "component---src-pages-group-orders-js" */),
  "component---src-pages-grubhub-plus-for-employees-js": () => import("./../../../src/pages/grubhub-plus-for-employees.js" /* webpackChunkName: "component---src-pages-grubhub-plus-for-employees-js" */),
  "component---src-pages-how-grubhub-works-js": () => import("./../../../src/pages/how-grubhub-works.js" /* webpackChunkName: "component---src-pages-how-grubhub-works-js" */),
  "component---src-pages-hybrid-teams-js": () => import("./../../../src/pages/hybrid-teams.js" /* webpackChunkName: "component---src-pages-hybrid-teams-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individual-meals-js": () => import("./../../../src/pages/individual-meals.js" /* webpackChunkName: "component---src-pages-individual-meals-js" */),
  "component---src-pages-learning-center-js": () => import("./../../../src/pages/learning-center.js" /* webpackChunkName: "component---src-pages-learning-center-js" */),
  "component---src-pages-learning-center-search-js": () => import("./../../../src/pages/learning-center-search.js" /* webpackChunkName: "component---src-pages-learning-center-search-js" */),
  "component---src-pages-meals-for-digital-events-js": () => import("./../../../src/pages/meals-for-digital-events.js" /* webpackChunkName: "component---src-pages-meals-for-digital-events-js" */),
  "component---src-pages-meals-for-remote-employees-js": () => import("./../../../src/pages/meals-for-remote-employees.js" /* webpackChunkName: "component---src-pages-meals-for-remote-employees-js" */),
  "component---src-pages-ordering-occasions-js": () => import("./../../../src/pages/ordering-occasions.js" /* webpackChunkName: "component---src-pages-ordering-occasions-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-pay-card-js": () => import("./../../../src/pages/pay-card.js" /* webpackChunkName: "component---src-pages-pay-card-js" */),
  "component---src-pages-perks-js": () => import("./../../../src/pages/perks.js" /* webpackChunkName: "component---src-pages-perks-js" */),
  "component---src-pages-refer-grubhub-corporate-js": () => import("./../../../src/pages/refer-grubhub-corporate.js" /* webpackChunkName: "component---src-pages-refer-grubhub-corporate-js" */),
  "component---src-pages-seamless-grubhub-js": () => import("./../../../src/pages/seamless-grubhub.js" /* webpackChunkName: "component---src-pages-seamless-grubhub-js" */),
  "component---src-pages-seamless-to-grubhub-js": () => import("./../../../src/pages/seamless-to-grubhub.js" /* webpackChunkName: "component---src-pages-seamless-to-grubhub-js" */),
  "component---src-pages-self-service-information-js": () => import("./../../../src/pages/self-service-information.js" /* webpackChunkName: "component---src-pages-self-service-information-js" */),
  "component---src-pages-tax-exempt-food-delivery-js": () => import("./../../../src/pages/tax-exempt-food-delivery.js" /* webpackChunkName: "component---src-pages-tax-exempt-food-delivery-js" */),
  "component---src-pages-version-js": () => import("./../../../src/pages/version.js" /* webpackChunkName: "component---src-pages-version-js" */),
  "component---src-pages-ways-to-order-js": () => import("./../../../src/pages/ways-to-order.js" /* webpackChunkName: "component---src-pages-ways-to-order-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-local-js": () => import("./../../../src/templates/local.js" /* webpackChunkName: "component---src-templates-local-js" */),
  "component---src-templates-resource-single-js": () => import("./../../../src/templates/resource-single.js" /* webpackChunkName: "component---src-templates-resource-single-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */)
}

