import React, { createContext, useState } from 'react'

import initTealiumTracker from "./tealium-tracker";

const { trackEvent, trackPageLoad } = initTealiumTracker();

const defaultState = {
    //Set default page data for utag
    tealiumPageData: {
        pageGroup: "grubhub - lead generation",
        subGroup: "untracked",
        pageName: "untracked",
        brand: "grubhub"
    },
    pageHistory: []
}

const utag_data_dev = {
    businesstype: "",
    platformtype: "",
    brand: "",
    pageGroup: "",
    subGroup: "",
    eventcategory: "",
    eventaction: "",
    eventlabel: "",
    teal_event_type: "",
    interactiontype: "",
    pageName: "",
    tealium_random: "",
    tealium_timestamp_epoch: ""
}

export const Context = createContext(defaultState)

export const Provider = props => {
    // Initial values are obtained from the props
    const { children } = props

    // Use State to keep the values
    const [ tealiumPageData, setTealiumPageData ] = useState(defaultState.tealiumPageData)
    const [ pageHistory, setPageHistory ] = useState(defaultState.pageHistory)

    const handlePageView = (utag_data_prod) => { 

        const { GATSBY_ENV } = process.env;
        let utag_data = GATSBY_ENV === "production" ? utag_data_prod : utag_data_dev;
        /**
         * Takes the utag_data sent to the function and tracks the page view to 
         * Tealium then saves the page data to state for use in tracking
         * Tealium events below
         */
        const setPageView = () => {
            trackPageLoad(utag_data)
        }
        //Need to set this timeout here so that Tealium has time to update the document with the correct meta data before logging the page view
        setTimeout(setPageView, 500);
        setTealiumPageData(utag_data);
    }

    const saveUrlToPageHistory = (url) => { 
        /**
         * On navigation to a new page, save the previous page url
         * for use in the MarketoForm component to track what page 
         * brought a user to the form
         */
        if(pageHistory.length < 2){
            //If less than 2 urls are saved then just add the new url to the end
            setPageHistory([...pageHistory, url]);
        }else{
            // Save current array to a temp one
            let temp = [...pageHistory]
            // Splice out the value at index 0
            temp.splice(0,1)
            // Add in the new url at the end
            setPageHistory([...temp, url])
        }
    }

    const handleTealiumEvent = (event_data) => {
        
        /**
         * Combines the event data with the tealiumPageData 
         * to track the event in Tealium
         */
        const combinedEventData = {
            ...event_data,
            ...tealiumPageData
        }
        trackEvent(combinedEventData);
    }

    const analyticsContext = {
        handlePageView,
        pageHistory,
        saveUrlToPageHistory,
        handleTealiumEvent
    }

  // pass the value in provider and return
  return <Context.Provider value={analyticsContext}>{children}</Context.Provider>
}

export const { Consumer } = Context
